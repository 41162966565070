import { graphql, navigate } from "gatsby";
import Img from "gatsby-image"
import React from 'react';
import styled from 'styled-components';

import Body from '../components/type/body';
import Button from '../components/controls/button';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { decode } from '../components/love/message';
import Card from '../components/love/card';
import Submit from '../components/love/submit-button';
import Subtitle from '../components/type/subtitle';

import colors from '../components/love/colors';
import templates from '../components/love/templates';

const products = [
  {
    desc: "powerful personal massager",
    handle: "vibe",
    id: 1,
    link: "https://getmaude.com/products/vibe-personal-massager?utm_source=vday&utm_medium=lp&utm_campaign=vibe",
    price: "$45",
    title: "vibe."
  },
  {
    desc: "skin-softening, jojoba oil-based massage candle",
    handle: "burn",
    id: 2,
    link: "https://getmaude.com/products/burn-massage-candle?utm_source=vday&utm_medium=lp&utm_campaign=burn",
    price: "$25",
    title: "burn."
  },
  {
    desc: "ultra-thin, easy-to-open latex condoms",
    handle: "rise",
    id: 3,
    link: "https://getmaude.com/products/rise-condoms?utm_source=vday&utm_medium=lp&utm_campaign=rise",
    price: "$12 / 10 pack",
    title: "rise"
  }
];

const SendYourOwn = styled(Button)`
  font-weight: 500;
  letter-spacing: 1px;
  margin: 48px auto 0;
  max-width: 256px;
  padding: 0 25px;
  width: 100%;
`;

const ProductHeader = styled(Subtitle)`
  padding: 64px 24px 48px;
  text-align: center;
`;

const ProductList = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 80px;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: 1096px;
  padding: 0 24px 80px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 80px 80px;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 312px;
  width: 100%;
`;

const ProductTitle = styled(Body)`
  font-weight: 500;
  margin-top: 16px;
`;

const ProductPrice = styled(Body)`
  margin-top: auto;
`;

const ShopButton = styled(Submit)`
  color: hsl(0, 0%, 28%) !important;
  margin-top: 21px;
`;

export const query = graphql`
  query {
    vibe: file(relativePath: {eq: "products/vibe.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 312, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    burn: file(relativePath: {eq: "products/burn.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 312, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rise: file(relativePath: {eq: "products/rise.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 312, quality: 85) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class Message extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const path = window.location.pathname;
    const parts = path.split("/");
    const encoded = parts.slice(-1)[0];

    if (encoded) {
      const [colorId, templateId, name, content] =  decode(encoded);
      const color = colors.find(color => color.id === parseInt(colorId));
      const template = templates.find(template => template.id === parseInt(templateId));

      this.setState({
        color,
        content,
        loading: false,
        name,
        template
      });
    }
    else {
      navigate("/love", { replace: true });
    }
  }

  render() {
    const {
      color,
      content,
      loading,
      name,
      template: Template
    } = this.state;
    const { data } = this.props;

    if (loading) {
      return (
        <Layout themeName='dark' flex={true}>
          <SEO title="Maude | Love" />
          <h1>Loading</h1>
        </Layout>
      );
    }

    return (
      <Layout themeName='dark' flex={true}>
        <SEO title="Maude | Love" />
        <Card cardColor={ color.value } viewOnly={ true }>
          <Template
            content={ content }
            loading={ false }
            messageValid={ true }
            name={ name }
            onSubmit={ () => {} }
            updateContent={ () => {} }
            updateName={ () => {} }
            viewOnly={ true }
          />
        </Card>
        <SendYourOwn as="div" onClick={ () => navigate('/') }>
          Send Your Own
        </SendYourOwn>
        <ProductHeader>
          you might be interested in
        </ProductHeader>
        <ProductList>
          {
            products.map(product => (
              <Product key={ product.id }>
                <Img fluid={ data[product.handle].childImageSharp.fluid } />
                <ProductTitle>
                  { product.title }
                </ProductTitle>
                <Body>
                  { product.desc }
                </Body>
                <ProductPrice>
                  { product.price }
                </ProductPrice>
                <ShopButton as="a" href={ product.link }>
                  Shop Now
                </ShopButton>
              </Product>
            ))
          }
        </ProductList>
      </Layout>
    );
  }
}

export default Message;
