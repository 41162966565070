import styled from 'styled-components';

export default styled.h2`
  color: hsl(0, 0%, 100%);
  font-family: 'GT America', 'Helvetica Neue', Helvetica, Arial, Tahoma, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  margin: 0;
  padding: 0;
`;

